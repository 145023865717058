import { useContext, useEffect, useState } from "react"
import { SOFormContext } from "../../pages/SOForm"
import { getReq, postReq } from "../../utils/apiCalls"
import { useNavigate } from "react-router-dom"

export const PriceDialog = () => {
    const { SOFormUser, setSOFormUser } = useContext(SOFormContext)
    const [pricingStage, setPricingStage] = useState(SOFormUser.formData.pricing_stage || '')
    const [msg, setMsg] = useState('')
    const navigate = useNavigate()


    const seedStage = [
        'Guaranteed Presentation Slot',
        '1 attendee ticket with unlimited access',
        'Access to leading VCs, Corporate VCs, private investors, and investment bankers',
        '3-minute presentation',
        'One-on-One Matchmaking & Networking app',
        'Two-page Company Profile published in digital event guide distributed to all attendees and investors for an additional $195',
        'Media Exposure',
    ]

    const ventureStage = [
        'Guaranteed Presentation Slot',
        '3 attendee tickets for company executives',
        'Access to leading VCs, Corporate VCs, private investors, and investment bankers',
        '6-minute presentation slot',
        'One-on-One Matchmaking & Networking app (early access)',
        'Two-page Company Profile published in digital event guide distributed to all attendees and investors',
        'Additional discounted tickets available for your team',
        'Media Exposure',
        // 'Coaching Session with Investors(online, 1 - 2 weeks before the summit)',
        '3 tickets to Cocktail Reception',
    ]

    useEffect(() => {
        getReq('https://youngstartup.io/api/cwebsite/get_current_event')
            .then(res => res.json())
            .then(res => setSOFormUser({ ...SOFormUser, eventData: res }))
    }, [])

    const handelChecked = (e) => {
        setMsg('')
        if (e.target.checked) {
            setPricingStage(e.target.name)
        } else {
            setPricingStage('')
        }
    }

    const handelSubmit = () => {
        if (!pricingStage) return setMsg('Please select a stage')
        getReq(`https://youngstartup.io/api/website/user/analytics/accepted_pricing?pricing_stage=${pricingStage}`)
            .then(() => setSOFormUser({ ...SOFormUser, formData: { ...SOFormUser.formData, pricing_stage: pricingStage }, currStage: 'SOFormDialog' }))
    }

    const handelDecline = () => {
        getReq('https://youngstartup.io/api/website/user/analytics/declined_pricing')
            .then(() => localStorage.removeItem('tok'), navigate('/login'))
    }

    return (
        <div className='price-dialog dialog'>
            <h1>Top Innovator Application</h1>
            <h5>There are no application fees.</h5>
            <p><span>The Venture Summit</span> will honor <span>more than 100 Top Innovators</span> by offering them a presentation and demo opportunity for venture backed and emerging growth companies looking to raise visibility and funding to present live to groups of Active Venture Capitalists, Corporate VCs, Private Investors, Investment bankers, Press, entrepreneurs, and corporate development officers.</p>
            <h5>Please select your Stage: </h5>
            <h6>If selected to present, the below fees would be relevant</h6>
            <section>
                <div className={`input ${pricingStage === 'growth' ? 'checked' : ''}`}>
                    <label htmlFor="growth">Growth Stage: $1985 <span>For companies that have raised more than $3M</span></label>
                    <input type="checkbox" name="growth" id="growth" onChange={handelChecked} checked={pricingStage === 'growth' ? true : false} />
                </div>
                <div className={`input ${pricingStage === 'venture' ? 'checked' : ''}`}>
                    <label htmlFor="venture">Venture Stage: $1785 <span>For companies that have raised up to $3M</span></label>
                    <input type="checkbox" name="venture" id="venture" onChange={handelChecked} checked={pricingStage === 'venture' ? true : false} />
                </div>
                <div className={`input ${pricingStage === 'seed' ? 'checked' : ''}`}>
                    <label htmlFor="seed">Seed Stage: No cost to present (Ticket Purchase Required $497) <span>For companies that have raised less than $500K and are seeking Seed/Angel Funding of up to $2M.</span></label>
                    <input type="checkbox" name="seed" id="seed" onChange={handelChecked} checked={pricingStage === 'seed' ? true : false} />
                </div>
            </section>
            <h5>If selected, benefits would include:</h5>
            {!pricingStage && <p>Please select a stage to see the benefits thereof.</p>}
            <ul>
                {pricingStage && (pricingStage === 'seed' ? seedStage : ventureStage).map((benefit, idx) =>
                    <li key={idx}>{benefit}</li>
                )}
            </ul>
            <h4>{SOFormUser.eventData?.pricing_message}</h4>
            {msg && <h4 className='message'>{msg}</h4>}
            <div className='btns'>
                <button onClick={handelDecline} className='btn'>Decline</button>
                <button onClick={handelSubmit} className='btn'>Continue</button>
            </div>
        </div>
    )
}






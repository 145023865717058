import { NavLink } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";

import { svgs } from '../../assets/svgs';
import { useContext } from 'react';
import { LandingContext } from '../../pages/LandingContext';
import styles from './SideBar.module.css';

const pages = [
   { icon: svgs.home, name: 'Home', link: 'home', },
   { icon: svgs.avatar, name: 'Featured Info', link: 'info', },
   { icon: svgs.marketingTools, name: 'Marketing Tools', link: 'marketing_tools', },
   { icon: svgs.listnote, name: 'Summary Outline', link: 'summary', },
   { icon: svgs.board, name: 'Powerpoint Presentation Upload', link: 'presentation_upload', },
   { icon: svgs.ticket, name: 'Assign My Tickets', link: 'assign', },
   { icon: svgs.calendar, name: 'Presentation Schedule', link: 'presentation_schedule', },
   { icon: svgs.info, name: 'Guidelines & Deadlines', link: 'guide', },
];


export const SideBar = ({ isSideBarOpen, toggleSideBar }) => {
   const { landingData } = useContext(LandingContext)

   const unsetLocalTok = (tok) => {
      localStorage.removeItem("tok");
   };

   return (
      <div
         className={`${styles.sideBar} ${isSideBarOpen && styles.open}`}
         onMouseEnter={() => window.innerWidth > 650 && toggleSideBar()} onMouseLeave={() => window.innerWidth > 650 && toggleSideBar()}>
         <button className={styles.mobileHamburger} onClick={() => window.innerWidth < 650 && toggleSideBar()}>
            {svgs.hamburger}
            <span >{svgs.youngstartups}</span>
         </button>
         <ul>
            <li className={styles.menu}>
               <button className={styles.listBtn} onClick={() => window.innerWidth < 650 && toggleSideBar()}>
                  {svgs.hamburger}
                  <span >{svgs.youngstartups}</span>
               </button>
            </li>
            {
               pages.filter(i => ((landingData.user.so_application.so_marking.final_presenting_track?.toLowerCase() === "seed" &&
                  landingData.so_inclusion?.toLowerCase() !== "yes")
                  ? i.link !== "summary"
                  : true)
               ).map(page =>
                  <li id='landing' key={page.name} onClick={() => window.innerWidth < 650 && toggleSideBar()}>
                     <NavLink to={page.link} className={`${page.link === 'home' && 'avtive'} ${styles.listBtn}`}>
                        {page.icon}
                        <span>{page.name}</span>
                     </NavLink>
                     <span>{page.name}</span>
                  </li>
               )
            }
         </ul>
         <section>
            <h4>Questions or issues? Please contact </h4>
            <a
               href="mailto:topinnovators@youngstartup.com"
               target="_blank"
               rel="noopener noreferrer"
            >
               topinnovators@youngstartup.com
            </a>
            <button onClick={() => { unsetLocalTok(); window.location.href = "/login"; }}>
               Log Out <LogoutIcon color='black' sx={{ height: '50%' }} />
            </button>
         </section>
      </div>
   )
}
